import React from 'react';
import './Article.css';

import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

class Article extends React.Component {

  render() {
    return (
      <div className="afb-article">
        <Container>
          <Row>
            {/*If we have an alt header image, use it.*/}
            <Col className="text-center"><Image src={this.props.project.altImage ? this.props.project.altImage : this.props.project.image} fluid /></Col>
          </Row>
          <Row>
            <Col className="text-center"><h1>{this.props.project.title}</h1></Col>
          </Row>
          <Row>
            {/*If we have article content, use it. Otherwise use the card description.*/}
            <Col><p>{this.props.project.content ? this.props.project.content : this.props.project.body}</p></Col>
          </Row>
          <Row>
            {this.props.project.html ? this.props.project.html : <span></span>}
          </Row>
        </Container>
      </div>
    );
  }
}
export default Article;
