import React from 'react';
import './editor.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

class Editor extends React.Component {

  outputPortfolioProjects() {
    let projectList = this.props.projects.map((project, i) => (
        <tr key={"table-row-" + i}>
          <td>{project.title}</td>
          <td><a href="/">Edit</a></td>
          <td><a href="/">Delete</a></td>
        </tr>
    ));
    return projectList;
  }

  render() {
    return (
      <div className="afb-editor">
        <Container>
          <Row>
            <Col>
              <h1>Administration and Settings</h1>
              <p>You can add portfolio projects, edit/delete old portfolio projects, or delete your account from this page.</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs defaultActiveKey="profile" id="admin-tabs">
                <Tab eventKey="profile" title="Profile">
                  <Row>
                    <Col>
                      <hr/>
                      <h2>Wipe out my account.</h2>
                      <p>All of your information and files will be deleted from our storage. We will not retain any of your data and it will not be able to be restored.</p>
                      <Button variant="danger">Permenantly delete my account.</Button>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="layout" title="Layouts">
                  <div>Layout</div>
                </Tab>
                <Tab eventKey="projects" title="Projects">
                  <Row>
                    <Col>
                    <hr/>
                      <Form>
                        <h2>Add a new portfolio project.</h2>
                        <Form.Group controlId="formBasicTitle">
                          <Form.Label>Project title *</Form.Label>
                          <Form.Control required type="text" placeholder="Enter the title." />
                          <Form.Text className="text-muted">
                            
                          </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicDescription">
                          <Form.Label>Project card description *</Form.Label>
                          <Form.Control required type="text" placeholder="Enter a short description." />
                          <Form.Text className="text-muted">
                            A short description of your project for use on the gallery card.
                          </Form.Text>
                        </Form.Group>

                        <Row>
                          <Col>
                            <Form.Group controlId="formCardImgUpload">
                              <Form.Label>Project card image *</Form.Label>
                              <Form.Control required type="file" placeholder="Upload your image." />
                              <Form.Text className="text-muted">
                                A small compressed image of your project for use on the gallery card.
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group controlId="formPageImgUpload">
                              <Form.Label>Project page image</Form.Label>
                              <Form.Control type="file" placeholder="Upload your image." />
                              <Form.Text className="text-muted">
                                A large hero image for use on your project's page. If no file is uploaded, your project card image will be used.
                              </Form.Text>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Form.Group controlId="formBasicPageContent">
                          <Form.Label>Project page content</Form.Label>
                          <Form.Control as="textarea" placeholder="Enter the content for your project page." />
                          <Form.Text className="text-muted">
                            A long description of your project for use on your projects' page. This can contain HTML and will default to your <em>Project Description</em> if left blank.
                          </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formBasicEmbedHtml">
                          <Form.Label>Embed HTML</Form.Label>
                          <Form.Control as="textarea" placeholder="Enter the content for your project page." />
                          <Form.Text className="text-muted">
                            Enter custom HTML that you want embedded at the bottom of the page. Most React-Bootstrap elements are supported. (Recommended for devleoper-use only.)
                          </Form.Text>
                        </Form.Group>
                          <Row>
                            <Col>
                              <Form.Group controlId="formBasicTag">
                                <Form.Label>Tag *</Form.Label>
                                <Form.Control required type="text" placeholder="Enter the tag." />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group controlId="formBasicTag2">
                                <Form.Label>Additional Tag</Form.Label>
                                <Form.Control type="text" placeholder="Enter the tag." />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group controlId="formBasicTag3">
                                <Form.Label>Additional Tag</Form.Label>
                                <Form.Control type="text" placeholder="Enter the tag." />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Form.Text className="text-muted">Enter at least 1 tag for your project. If you enter a new tag, it will appear in the right-side nav menu.</Form.Text>
                        
                          <Row><Col><Button variant="primary" type="submit">
                          Submit
                        </Button></Col></Row>
                      </Form>
                    </Col>
                  </Row>
                    <Row>
                      <Col>
                        <hr/>
                        <h2>Edit or delete portfolio projects.</h2>
                        <p>Use the table below to edit or delete old projects. Note: any action taken is permanent.</p>
                        <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Project Name</th>
                            <th colSpan="2">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.outputPortfolioProjects()}
                        </tbody>
                      </Table>
                      </Col>
                    </Row>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Editor;
