import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './bio.css';

class Bio extends React.Component {
  render() {
    return (
      <div className="afb-bio">
        <Container>
          <Row className="afb-bio-header">
            <Col xs={5}>
              <h1>Hello!</h1>
              <h1 className="afb-blue">My name is Alex Billedeaux.</h1>
            </Col>
            <Col>
              <img className="afb-profile-picture" alt="profile" src="profilePicture.jpg" />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Thanks for coming to take a look at my portfolio. I'm a software developer and writer working out of the West Michigan area. I've built a ReactJS/Redux web app, constructed Wordpress templates from scratch, and managed the hosting for a branding agency, but I've also published a horror fiction novel and written copy for large-scale email campaigns.</p>
              <p>If you know of someone who needs a programmer who can communicate with a team and with customers, please shoot me an email at <a href="mailto:alexbilledeaux@gmail.com">alexbilledeaux@gmail.com</a>!</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Bio;
