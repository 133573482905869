import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Link from 'react-router-dom/Link';

import './navbar.css';


class Navigation extends React.Component {

  renderTags() {
      let tagList = this.props.tags.map((tag, i) => (
        <Nav.Link key={"nav-link-"+ i} className={this.props.currentTag === tag ? 'active' : 'inactive'} onClick={(e) => this.props.handleNavClick(e)} name={tag}>{tag}</Nav.Link>
    ));
    return tagList;
  }

  render() {
    return (
      <React.Fragment>
        <div className="afb-nav-spacer"></div>
        <div className="afb-nav">
          <Navbar bg="light" expand="lg">
            <Navbar.Brand href="/">
              <img alt="afb-logo" className="afb-logo" src="/logo.png" />
              <div className="afb-portfolio-title">
                <div>BILLEDEAUX</div>
                <div>PORTFOLIO</div>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Link className="afb-nav-link" to="/">Projects</Link>
                <Link className="afb-nav-link" to="/bio">Bio</Link>
              </Nav>
              <Nav variant="pills">
                <Nav.Link className={this.props.currentTag === "All Projects" ? 'active' : 'inactive'} onClick={this.props.removeFilter}>All Projects</Nav.Link>
                {this.renderTags()}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </React.Fragment>
    );
  }
}
export default Navigation;
