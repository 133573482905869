import React from 'react';
import Navigation from './components/navigation/Navigation';
import Gallery from './components/gallery/gallery';
import Article from './components/article/Article';
import Bio from './components/bio/bio';
import Editor from './components/editor/Editor';
import { BrowserRouter, Route } from 'react-router-dom'; 
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//Adding some bootstrap support for HTML embeds
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      projects: [

        {tags: ["Wordpress"], title: "West Michigan Lumber", image:"/imgs/wml.gif", body: "A custom Wordpress theme, with interesting image dividers. Built from scratch.", button: "Read More"},
        {tags: ["Wordpress"], title: "Fresh Start Markets", image:"/imgs/freshStart.gif", body: "This custom Wordpress theme features a background video and an artistic slider.", button: "Read More"},
        {tags: ["SVG", "HTML/CSS"], title: "Big Honcho Media", image:"/imgs/bigHoncho.gif", body: "This landing page was built in raw html and features eye-catching SVG animations.", button: "Read More"},
        {tags: ["Wordpress"], title: "Kearney Creative", image:"/imgs/kearney.gif", body: "A custom Wordpress theme that showcases the work of a fantastic videographer.", button: "Read More"},
        {tags: ["ReactJS", "AWS"], title: "Eschew Notes", image:"/imgs/eschewNotes.gif", body: "A ReactJS web app that helps gamers run their favorite tabletop games online.", button: "Read More"},
        {tags: ["ReactJS"], title: "Macro Calculator", image:"/imgs/calculator.gif", body: "A calculator to help users manage their nutrition. Built on ReactJS.", button: "Read More"},
        {tags: ["ReactJS", "npm"], title: "React Autocomplete Searchbar", image:"/imgs/autocomplete.gif", body: "A ReactJS input component that gives the user autocomplete suggestions.", button: "Read More"},
        {tags: ["Wordpress"], title: "Skillmonkey Blog Theme", image:"/imgs/skillmonkey.gif", body: "This theme is styled to look like a character sheet from a tabletop roleplaying game.", button: "Read More"},

        // {tags: ["Halo", "Organic"], title: "Commander Palmer", image:"https://media3.giphy.com/media/DoROfADqjXQdO/giphy.gif", body: "Currently assigned as the Commanding Officer of the 300-member Spartan contingent aboard UNSC Infinity.", button: "Read More"},
        // {tags: ["Halo", "Organic"], html: <Table striped bordered hover variant="dark"><thead><tr><th>Years of Service</th><th>Engagements</th><th>Confirmed Kills</th><th>Rank</th></tr></thead><tbody><tr><td>3</td><td>3</td><td>9</td><td>Captain</td></tr><tr><td>4</td><td>7</td><td>28</td><td>Specialist (Agent)</td></tr><tr><td>5</td><td>18</td><td>76</td><td>Spartan-IV</td></tr></tbody></Table>,title: "Spartan Locke", image:"https://66.media.tumblr.com/418e6a7cd4918c763536ed77dfdf0b58/tumblr_pgz57kJRzY1uqcakjo1_400.gifv", body: "Locke (service number 73808-3153-JL) is a Spartan-IV supersoldier and a former Office of Naval Intelligence agent.", button: "Read More"},
        // {tags: ["Halo", "Synthetic"], title: "Cortana A.I.", image:"https://media1.giphy.com/media/MYgcGNaJ3b572/giphy.gif", body: "UNSC Artificial intelligence (SN: CTN 0452-9), is a smart artificial intelligence based on the mind of Dr. Halsey.", button: "Read More"},
        // {tags: ["Halo", "Organic"], title: "Spartan Buck", altImage:"https://content.halocdn.com/media/Default/encyclopedia/characters/edward-buck/buck-05112015/buck-708x398-c37abe31363b4401b22782bdb5813c0a.jpg", content:<p>After the disbanding of Alpha-Nine, Buck was happy to let someone else make the decisions as part of Fireteam Osiris instead of being a leader. This was because his inability to see Mickey's betrayal coming had shaken Buck's confidence in his own leadership skills and left him preferring to let others make the decisions for him.<sup id="cite_ref-Bad_Blood_8-4" class="reference"><a href="#cite_note-Bad_Blood-8">[8]</a></sup> However, Buck's abilities as a leader were so valued that Captain <a href="/Thomas_Lasky" title="Thomas Lasky">Thomas Lasky</a> told Buck that if he ever wanted to leave Osiris to lead his own fireteam again, <a href="/Sarah_Palmer" title="Sarah Palmer">Sarah Palmer</a> would have no trouble giving him one in a heartbeat.<sup id="cite_ref-73" class="reference"><a href="#cite_note-73">[70]</a></sup><br/><br/>When approached to reunite Alpha-Nine for a mission during the <a href="/Created_conflict" title="Created conflict">Created conflict</a>, Buck was reluctant to become a leader again following his perceived failures. After being the leader of Alpha-Nine again as well as finally understanding that he wasn't at fault for Mickey's actions, Buck's confidence was restored to the point that he left Osiris to lead his old team again full time.<sup id="cite_ref-Bad_Blood_8-5" class="reference"><a href="#cite_note-Bad_Blood-8">[8]</a></sup></p>, image:"https://media1.tenor.com/images/165ba25fbd3a12ed32a3bbdf7ab201e4/tenor.gif?itemid=14629898", body: "Following the a turn as an ODST, Buck was promoted to SPARTAN-IV and eventually joined Fireteam Osiris, led by Jameson Locke.", button: "Read More"},
        // {tags: ["Halo", "Organic"], html: <Jumbotron><h2>"Wake me...When you need me."</h2><p>The chief was in cryogenic stasis for 4 years, 7 months, and 10 days about the UNSC Foward Unto Dawn, before being re-awoken following the return of the Forerunners and the Guardians in Halo 4.</p><Button href=''>Read More At Halo Waypoint</Button></Jumbotron>, title: "Master Chief 117", image:"https://media0.giphy.com/media/XGaUQ9DFSmTsL3WcBl/source.gif", body: "A towering biochemically and cybernetically-enhanced supersoldier, raised and trained from an early age to be a weapon.", button: "Read More"},
        // {tags: ["Halo", "Organic"], title: "Sergeant Johnson", image:"http://38.media.tumblr.com/ea030232a79bec7be5a5b42f8558a4b8/tumblr_n9aa8eJy1h1r3bvmpo1_r1_500.gif", body: "A senior non-commissioned officer of the UNSC Marine Corps who served during the Human-Covenant War. Sergeant Major Johnson was killed by monitor 343 Guilty Spark.", button: "Read More"},
        // {tags: ["Mass Effect", "Organic"], title: "Jack", altImage: "http://31.media.tumblr.com/tumblr_loqq8gh89u1qgwjmxo1_500.gif", image:"https://media.giphy.com/media/KMnTrvAHicP1m/giphy.gif", body: "Also known as Subject Zero, Jack is a notorious criminal whose crimes include piracy, kidnapping, vandalism and murder. She is also a powerful biotic.", button: "Read More"},
        // {tags: ["Mass Effect", "Organic"], title: "Dr. Liara Tsoni", image:"https://media1.tenor.com/images/95061a2087beb9539d02dc4c47d6c274/tenor.gif?itemid=8119285", body: "An asari researcher who has spent the past fifty years of her life studying Prothean technology and culture, specialising in the Prothean extinction.", button: "Read More"},
        // {tags: ["Mass Effect", "Synthetic"], title: "Legion", image:"https://media.giphy.com/media/10Kc2SrJPpNeTe/giphy.gif", body: "Legion is the name taken by the gestalt consciousness formed by 1183 geth programs inhabiting a unique geth 'mobile platform'. An ally of Commander Sheppard.", button: "Read More"},
        
        // {tags: ["Game", "C#"], title: "Deathwish Donny", image:"https://i.imgur.com/lJhSyIH.gif", altImage: "https://i.imgur.com/C8fTNYr.gif", body: "Fire an indestructible man out of a cannon and try your hardest to kill him in this bloody platformer.", content: <div><h4>Donny Dare, the world’s premier circus performer, has avoided death a thousand times.</h4><Row><Col><p>He is a master fire-eater, the king of lion taming, and the only man to motorcycle through a ring of fire while juggling chainsaws. The carnival’s new Human Cannonball will be Donny’s biggest stunt yet, but Donny’s fame has made him enemies.</p><p><b>You play as Ken, a disgruntled cannon operator, who has sworn to kill the man who cannot be killed.</b></p></Col><Col><img alt="donny dare" src="https://i.imgur.com/pHw0Bjm.png" /></Col></Row><Row><Col><Jumbotron><h3>Blueberry Game Jam Winner</h3><p><b>#1 Community Vote | Top 3 Overall</b></p><p>The Blueberry Jam was a week-long game jam with the theme <em>"Are you trying to get yourself killed? Yes, yes you are."</em> It had 20 entries, each of which were scored 1-5. Deathwish Donny got an average of 4.2 out of all categories. Our team was comprised of an artist, a composer, and a developer (me). I built the game in Unity and scripted it in C#. <b>Play it below!</b></p><div class="game-embed"></div></Jumbotron></Col></Row></div>, button: "Read More"}
      ],
      activeProjects: [],
      tags: [],
      currentTag: 'All Projects'
    };
  }

  getAllTags() {
    let tagList = [];
    //Loop through all of the possible projects
    for (let i = 0; i < this.state.projects.length; i++) {
      //Loop through each project's tags
      this.state.projects[i].tags.forEach(function (element) {
        //If we don't have the tag, grab it
        if (!tagList.includes(element)) {
          tagList.push(element);
        }
      });
    }
    this.setState({
      tags: tagList
    });
  }

  removeFilter() {
    //Display all projects
    this.setState({
      activeProjects: this.state.projects,
      currentTag: 'All Projects'
    });
  }

  filterActiveProjects(e) {
    let newActiveProjects = [];
    let filterTag = e.target.getAttribute('name');
    for (let i = 0; i < this.state.projects.length; i++) {
      if (this.state.projects[i].tags.includes(filterTag)) {
        newActiveProjects.push(this.state.projects[i]);
      }
    }
    this.setState({
      activeProjects: newActiveProjects,
      currentTag: filterTag
    });
  }

  componentDidMount() {
    //Set the initial filter to "all"
    this.removeFilter();
    this.getAllTags();
  }

  constructProjectRoutes() {
    //Programmatically build pages for each of the projects defined in the project array
    let projectRoutes = this.state.projects.map((project, i) => (
        <Route key={"route-"+ i} exact path={"/" + project.title}>
          <Article project={project}></Article>
        </Route>
    ));
    return projectRoutes;
  }

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Navigation currentTag={this.state.currentTag} removeFilter={() => this.removeFilter()} handleNavClick={(e) => this.filterActiveProjects(e)} tags={this.state.tags}></Navigation>
          <Route exact path="/">
            <Gallery projects={this.state.activeProjects} />
          </Route>
          <Route exact path="/bio">
            <Bio />
          </Route>
          <Route exact path="/admin">
            <Editor projects={this.state.projects} />
          </Route>
          {this.constructProjectRoutes()}
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
