import React from 'react';
import Card from 'react-bootstrap/Card';

import './portfoliocard.css';

import Link from 'react-router-dom/Link';

class PortfolioCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
        <Card style={{ width: '18rem' }}>
            <div className="afb-card-image" style={{ backgroundImage: "url("+this.props.image+")" }}></div>
            <Card.Body>
                <Card.Title>{this.props.title}</Card.Title>
                <Card.Text>
                {this.props.body}
                </Card.Text>
                <div className="btn-container">
                  <Link className="btn btn-primary" onClick={this.scrollToTop} to={"/" + this.props.title}>{this.props.button}</Link>
                </div>
            </Card.Body>
        </Card>
    );
  }
}
export default PortfolioCard;
