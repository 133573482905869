import React from 'react';
import './gallery.css';

import PortfolioCard from './portfoliocard';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Gallery extends React.Component {

  renderProjects() {
    let projectList = this.props.projects.map((project, i) => (
        <Col key={"col-"+ i}><PortfolioCard title={project.title} image={project.image} body={project.body} button={project.button} /></Col>
    ));
    return projectList;
  }

  render() {
    return (
      <div className="afb-gallery">
        <Container>
          <Row>
            {this.renderProjects()}
          </Row>
        </Container>
      </div>
    );
  }
}
export default Gallery;
